<template>
  <div class="img-wrapper">
    <img src="@/assets/sold-out.png" alt="" class="img" />
    <p>您来晚啦，商品已下架~</p>
  </div>
</template>

<script>
export default {
  name: "SoldOut",
};
</script>

<style lang="less" scoped>
.img-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f6fb;
  padding-top: 40%;
  box-sizing: border-box;
  .img {
    width: 60%;
  }
  p{
    color: #9a9a9a;
  }
}
</style>
